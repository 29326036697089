
import { objectDifference } from "@/helpers";
import {
  quoteMapActions,
  quoteMapMutations,
  quoteMapState
} from "@/store/modules/quote";
import { cloneDeep, pick } from "lodash";
import Vue from "vue";
import OldRatingCustomAlert from "./RatingApplication/OldRatingCustomAlert.vue";
import UnderwriterInfo from "./Components/UnderwriterInfo.vue";

export default Vue.extend({
  name: "loss-report",
  components: {
    LossReportViewer: () =>
      import("@/views/shared/quotes/Components/LossReportViewer.vue"),
    QuoteSteps: () => import("@/views/shared/quotes/QuoteSteps.vue"),
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue"),
    OldRatingCustomAlert,
    UnderwriterInfo
  },
  data() {
    return {
      editingLossData: {} as any,
      originalLossData: {} as any,
      loading: false,
      loadingText: "",
      quoteId: ""
    };
  },
  mounted() {
    this.$nextTick(() => this.prepareEditingData());
    this.quoteId = this.$route.params.quoteId;
  },
  computed: {
    ...quoteMapState(["editing", "makingApiRequest"]),
    updatedFields(): any {
      return objectDifference(this.editingLossData, this.originalLossData);
    },
    quoteLink(): string {
      return `/quotes/${this.quoteId}/property-rating`;
    },
    hasLossReport(): boolean {
      return (
        this.editing &&
        this.editing.lossReport &&
        this.editing.lossReport.lossInformation.length > 0
      );
    },
    lossInformation(): any[] {
      if (this.hasLossReport) {
        return this.editing.lossReport.lossInformation;
      }
      return [];
    },
    numberOfClaimsFromLossReport(): number {
      // lossInformation: ILossInformation[]
      if (this.editing && this.editing.lossReport) {
        const { lossInformation } = this.editing.lossReport;
        let expectedNumberOfRelevantClaims = 0;
        for (const loss of lossInformation) {
          if (
            !!(
              loss?.relevantToRisk &&
              loss?.relevantToRisk.toLowerCase() === "yes"
            ) &&
            !!(
              loss?.weatherOrClaimAppliance &&
              loss?.weatherOrClaimAppliance.toLowerCase() === "no"
            )
          ) {
            expectedNumberOfRelevantClaims++;
          }
        }
        return expectedNumberOfRelevantClaims;
      }
      return 0;
    },
    hasRespondedToAllQuestions(): boolean {
      if (this.editing && this.editing.lossReport) {
        const { lossInformation } = this.editing.lossReport;
        for (const loss of lossInformation) {
          if (!loss?.relevantToRisk || !loss?.weatherOrClaimAppliance) {
            return false;
          }
        }
      }
      return true;
    },
    quoteHasBeenDeleted(): boolean {
      return !!(this.editing && this.editing.deleted);
    },
    hasRatings(): boolean {
      return (
        this.editing &&
        this.editing.ratingValidations &&
        this.editing.ratingValidations.hasSelectedRating
      );
    }
  },
  methods: {
    ...quoteMapActions(["step8SaveQuoteLossData"]),
    ...quoteMapMutations(["SET_EDIT"]),
    async saveQuoteLossData(event?: string): Promise<void> {
      const quoteId = this.$route.params.quoteId;
      if (event && event === "next") {
        this.$router.push(`/quotes/${quoteId}/remarks-and-mortgages`);
        return;
      }
    },
    async saveLossData() {
      try {
        this.loading = true;
        this.loadingText = "Saving loss report. Please wait...";
        const quoteId = this.$route.params.quoteId;
        const response = await this.step8SaveQuoteLossData({
          id: quoteId,
          payload: this.updatedFields
        });
        this.SET_EDIT(response.data);
        this.prepareEditingData();
      } catch (error) {
        if (error instanceof Error) {
          this.$notify.error(
            error.message ||
              "There was an issue saving your responses. Try again later"
          );
        }
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    prepareEditingData() {
      const editableFields = ["relevantToRisk", "weatherOrClaimAppliance"];
      const data: any = {};
      for (const lossReport of this.lossInformation) {
        data[lossReport._id] = pick(lossReport, editableFields);
      }
      this.editingLossData = cloneDeep(data);
      this.originalLossData = cloneDeep(data);
    },
    lossReportResponseChangedHandler(
      { key, value }: { key: any; value: any },
      id: any
    ): void {
      this.editingLossData[id][key] = value;
      this.editingLossData = { ...this.editingLossData };
      this.saveLossData();
    }
  }
});
